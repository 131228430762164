import { DependencyList, useRef } from 'react';
import { isArrayShallowEqual } from './is-array-shallow-equal';

/**
 * Like useMemo but it never releases due to memory pressure
 */
export const useSemanticMemo = <T>(
  factory: () => T,
  dependencies: DependencyList
): T => {
  const dependenciesRef = useRef<DependencyList>();
  const valueRef = useRef<T>();
  if (!isArrayShallowEqual(dependenciesRef.current, dependencies)) {
    valueRef.current = factory();
  }
  dependenciesRef.current = dependencies;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return valueRef.current!;
};
