import { RequestConfig } from 'packages/http-client/fetcher';
import { useConfigurableEagerHttp } from './use-configurable-eager-http';
import { useConfigurableLazyHttp } from './use-configurable-lazy-http';

// Re export some helpers from packages/http-client/fetcher
export {
  RequestConfig,
  createJsonBodyRequestConfig,
  JsonBodyRequestConfig,
  NotOkFetchResponse,
  OkResponseTypeOf,
  RejectedFetchError,
  RequestOpts
} from 'packages/http-client/fetcher';

/**
 * Provide a fetcher instance to this context in a parent of
 * any component that uses the below hooks
 */
export { FetcherContext } from './fetcher-context';

/**
 * 
 
 */
export {
  HttpClientErrorBoundary,
  HttpClientErrorBoundaryProps
} from './error-boundary';

/**
 * Most commonly used. This hook takes a request configuration. Using <Suspense />,
 * it halts rendering until the data is ready. It then provides the data to you
 * directly. If it gets a non-200..299 status code, it throws an error, that is
 * expected to be caught by an error boundary. NOTE: to not wrap this in a try/
 * catch to attempt to handle the error. NEVER wrap hooks in try catches.
 *
 * Usage Level: VERY FREQUENT
 */
export const useHttpQuery = <OkResponseBodyType>(
  requestConfig: RequestConfig<OkResponseBodyType>
): OkResponseBodyType => useConfigurableEagerHttp(requestConfig)[2];

/**
 * Similar to `useHttpQuery`. In this hook, non-200..299 status codes are not
 * treated as errors. Additionally, it returns the response object directly.
 * This means you must look at the response.status or response.ok properties
 * to determine the type of response you are looking at. DO NOT ASSUME 200 STATUS,
 * ALWAYS VERIFY.
 *
 * Usage Level: OCCASIONAL - Only when you expect to encounter READ errors from the API
 */
export const useHttpQueryWithErrors = (
  requestConfig: RequestConfig
): [Response, any] =>
  useConfigurableEagerHttp(requestConfig, true, false).slice(1, 3) as any;

/**
 * Similar to `useHttpQuery`. In this hook, there is a loading property returned.
 * The data may not be ready immediately, but this hook allows you to render a
 * a spinner or some other indicator as opposed to halting rendering using <Suspense />
 * This hook treats non-200..299 statuses as errors and throws them, similar to
 * `useHttpQuery`.
 *
 * Note: Perhaps you do not need to use this hook, maybe you want to create another
 * <Suspense /> closer to your component...?
 *
 * Usage Level: OCCASIONAL - Only when you need to handle a local loading case
 */
export const useHttpQueryWithLoading = <OkResponseBodyType>(
  requestConfig: RequestConfig<OkResponseBodyType>
): [boolean, OkResponseBodyType | null] => {
  const [loading, , data] = useConfigurableEagerHttp(requestConfig, false);
  return [loading, data] as any;
};

/**
 * A combination of `useHttpQueryWithLoading` and `useHttpQueryWithErrors`. This is a
 * very flexible hook, that allows you to handle non-200..299 status AND it allow you
 * to handle custom loading by not using <Suspense />.
 *
 * Usage Level: RARE - Only when you want to have control over the loading state AND
 * you expect to encounter errors.
 */
export const useHttpQueryWithLoadingWithErrors = (
  requestConfig: RequestConfig
): [boolean, Response | null, any | null] =>
  useConfigurableEagerHttp(requestConfig, false, false).slice(0, 3) as any;

/**
 * The most flexible `useHttpQuery*` hook. With this hook, you are given
 * all of the loading, and response and error indicators, as seen in
 * `useHttpQueryWithLoadingWithErrors`. Additionally, this hook gives you
 * a function to manually refresh the data.
 *
 * Note: Queries are automatically refreshed in the case of a mutation, so
 * do not worry too much about cache busting
 *
 * Usage Level: VERY RARE - Only use when you want to manually bust the cache
 * of your READ data, even though it is already busted when mutation requests
 * occur.
 */
export const useHttpQueryWithErrorsWithLoadingWithLazyRefresh = (
  requestConfig: RequestConfig
): [boolean, Response | null, any | null, () => Promise<void>] =>
  useConfigurableEagerHttp(requestConfig, false, false) as any;

/**
 * Similar to `useHttpQueryWithLoadingWithErrors`, this hook is used for
 * eager mutation requests. With this hook, you are given all of the loading,
 * and response and error indicators. You must handle non-200..299 responses
 * as they are not asserted automatically. Also, you must handle the loading
 * state as it does not integrate with <Suspense />
 *
 * CAUTION: This should not be regularly used; think through the trigger
 * for this mutation; is it in response to a user event? Most mutations are
 * triggered by user events. If so, you probably want to structure your
 * mutation as a Form and trigger the "lazy" request via a Form submission.
 *
 * See packages/http-client/react-forms for integration with Final Form
 *
 * Usage Level: RARE - Only when the true mutations are not in response to
 * a user event. Example: exchanging an auth token when the app is first loaded.
 */
export const useHttpMutationWithErrorsWithLoading = (
  requestConfig: RequestConfig
): [boolean, Response | null, any] =>
  useConfigurableEagerHttp(requestConfig, false, false, false).slice(
    0,
    3
  ) as any;

/**
 * PROBABLY SHOULD NOT USE DIRECTLY!
 *
 * Similar to `useHttpMutationWithErrorsWithLoading`, this hooks does not
 * fire a request upon first run, rather it provides a callback that is called
 * with a `RequestConfig` in order to trigger the request. This type of mutation
 * is often associated with user events that then lazily trigger the request.
 * Because of this, you likely do not to need this hook directly, rather use
 * an integration with a Form that triggers the mutation upon submit.
 *
 * See packages/http-client/react-forms for integration with Final Form
 *
 * Usage Level: VERY RARE - No good use case outside of Forms, which should use
 * a form-level adapter
 */
export const useHttpMutationWithErrorsWithLoadingWithLazyRequest = (): [
  boolean,
  Response | null,
  any,
  (requestConfig: RequestConfig) => void
] => useConfigurableLazyHttp(false);
