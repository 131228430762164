import {
  NotOkFetchResponse,
  RequestConfig
} from 'packages/http-client/fetcher';
import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { assertExpectedMutating } from './assertion-expected-mutating';
import { FetcherContext } from './fetcher-context';
import { useFetchTupleState } from './use-fetch-tuple-state';
import { useSemanticMemo } from './use-semantic-memo';

export const useConfigurableEagerHttp = (
  requestConfig: RequestConfig,
  suspense = true,
  assertResponse = true,
  query = true
): [
  boolean,
  Response | null,
  any,
  (bustCache: boolean) => Promise<[Response, any]>
] => {
  assertExpectedMutating(!query, requestConfig);

  const fetcher = useContext(FetcherContext);
  const [request, onUpdate, getStatus] = useSemanticMemo(
    () => fetcher(requestConfig),
    [JSON.stringify(requestConfig), fetcher]
  );

  const [
    getTupleState,
    setTupleState,
    setTupleStateWithoutRefresh
  ] = useFetchTupleState(getStatus());

  useEffect(() => onUpdate(setTupleState), [onUpdate, setTupleState]);

  const getCurrentTupleState = useCallback(() => {
    // Make sure we set our to the latest status before returning.
    setTupleStateWithoutRefresh(getStatus());
    return getTupleState();
  }, [getStatus, getTupleState, setTupleStateWithoutRefresh]);

  let [loadingState, responseState, dataState] = getCurrentTupleState();

  const forceRefreshRequest = useCallback(
    () => request(query), // Queries always bust cache
    [query, request]
  );

  // If we do not have a response, then we need to make a request.
  // Since they are de-dupped, its OK to maybe make an extra

  // If we do not have a response yet
  if (!responseState) {
    // Create a new request and conditionally throw it
    if (suspense) {
      // If a request has already been started, that is OK since this will
      // get us the promise for the request in progress
      throw request();
    }
    // Only create the new request if there isn't one already being worked on
    if (!loadingState) {
      request();
      // Re-tap the tuple to get changed values after request was started
      [loadingState, responseState, dataState] = getCurrentTupleState();
    }
  } else if (assertResponse) {
    // If needed, make sure the response is OK
    NotOkFetchResponse.assert(responseState, dataState);
  }

  const fetcherRef = useRef(fetcher);
  const [, setState] = useState({});

  // This is a hack to get around a know bug in suspense:
  // https://github.com/facebook/react/issues/18844
  useEffect(() => {
    if (fetcherRef.current != fetcher) {
      fetcherRef.current = fetcher;
      setState({});
    }
  }, [fetcher]);

  return [loadingState, responseState, dataState, forceRefreshRequest];
};
