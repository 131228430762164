import { RequestConfig } from 'packages/http-client/fetcher';

/**
 * Internal helper util to assert the proper mutation/query request type
 */
export const assertExpectedMutating = (
  expectedMutating: boolean,
  { mutating }: RequestConfig
) => {
  if (expectedMutating === !mutating) {
    throw new Error(
      expectedMutating
        ? 'Query request used with useHttpMutation*'
        : 'Mutation request used with useHttpQuery*'
    );
  }
};
