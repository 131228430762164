import { FetcherUpdateStatus } from 'packages/http-client/fetcher';
import { useCallback, useRef } from 'react';
import { isArrayShallowEqual } from './is-array-shallow-equal';
import { noop } from './noop';
import { useForceRefresh } from './use-force-refresh';

type RelevantTupleGetter = () => [boolean, Response | null, any];

/**
 * Common management of Fetcher Tuple state between the configurable hooks.
 *
 * This is tricky because we do not useState for our state since our real
 * source of truth comes from fetcher. We do not want to ever render stale
 * data which can occur if the request config changes post-mounting
 */
export const useFetchTupleState = (
  status: FetcherUpdateStatus
): [
  RelevantTupleGetter,
  (update: FetcherUpdateStatus) => void,
  (update: FetcherUpdateStatus) => void
] => {
  const forceRefresh = useForceRefresh();
  // Our state will be kept as a ref and we will choose when to refresh using useForceRefresh
  const statusRef = useRef<FetcherUpdateStatus>(status);

  // A setter that sets state and calls a callback on change
  const setStateWithAfter = useCallback(
    (nextUpdateStatus: FetcherUpdateStatus, after: () => void) => {
      if (!isArrayShallowEqual(statusRef.current, nextUpdateStatus)) {
        statusRef.current = nextUpdateStatus;
        after();
      }
    },
    []
  );

  // A setter that acts like setState
  const setStateWithRefresh = useCallback(
    (nextUpdateStatus: FetcherUpdateStatus) =>
      setStateWithAfter(nextUpdateStatus, forceRefresh as () => void),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Must be able to be a static reference forever
  );

  // A setter that does not queue a refresh. Use carefully...
  const setStateWithoutRefresh = useCallback(
    (nextUpdateStatus: FetcherUpdateStatus) =>
      setStateWithAfter(nextUpdateStatus, noop),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [] // Must be able to be a static reference forever
  );

  // Provider a getter for the state instead of the state directly.
  const getState = useCallback<RelevantTupleGetter>(() => {
    const { current } = statusRef;
    // Check if we have a non-null error in our tuple. Now is the time to throw it
    if (current[3]) {
      throw current[3];
    }
    return current.slice(0, 3) as any;
  }, []);

  return [getState, setStateWithRefresh, setStateWithoutRefresh];
};
