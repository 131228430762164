import { RequestConfig } from 'packages/http-client/fetcher';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { assertExpectedMutating } from './assertion-expected-mutating';
import { FetcherContext } from './fetcher-context';
import { noop } from './noop';
import { useFetchTupleState } from './use-fetch-tuple-state';

// Internal hook implementation, do not use directly
export const useConfigurableLazyHttp = (
  query: boolean
): [boolean, Response | null, any, (requestConfig: RequestConfig) => void] => {
  const fetcher = useContext(FetcherContext);
  const unsubscribeFromUpdatesRef = useRef<() => void>(noop);
  const isMountedRef = useRef(false);
  const [getTupleState, setTupleState] = useFetchTupleState([
    false,
    null,
    null,
    null
  ]);

  // Make a callback that can start a new request.
  const queueNewRequest = useCallback(
    (requestConfig: RequestConfig) => {
      // We have to make sure we have been mounted and therefore our unsubscribe
      // will be guaranteed to be called, otherwise we create a potential memory
      // leak by subscribing to something that may not be cleaned up if component
      // never fully mounts.
      if (!isMountedRef.current) {
        throw new Error('Eagerly requested lazy request');
      }
      // Assert that proper request type
      assertExpectedMutating(!query, requestConfig);
      const [request, onUpdate] = fetcher(requestConfig);
      // Unsubscribe from any potential previous request
      unsubscribeFromUpdatesRef.current();
      // Start the next request
      request();
      // Start listening for updates and save the unsubscribe on the ref
      unsubscribeFromUpdatesRef.current = onUpdate(setTupleState);
    },
    [fetcher, query, setTupleState]
  );

  useEffect(
    () =>
      // Mark as mounted
      (isMountedRef.current = true) &&
      // return a lambda that will unsubscribe any current subscriptions at un-mount time
      (() => unsubscribeFromUpdatesRef.current()),
    []
  );

  return [...getTupleState(), queueNewRequest] as any;
};
