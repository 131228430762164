import { Component, ReactNode } from 'react';
import {
  RejectedFetchError,
  NotOkFetchResponse,
  ServiceUnavailableError,
  NetworkConnectivityError
} from 'packages/http-client/fetcher';

export interface HttpClientErrorBoundaryProps {
  /**
   * Used when a fetch error occurs due to a 500
   */
  renderServiceUnavailable: (
    error: ServiceUnavailableError,
    retry: () => void,
    url: string,
    status: number
  ) => ReactNode;

  /**
   * Used when a fetch error occurs due a network issue; cors, dns failure, offline, etc...
   */
  renderNetworkConnectivity: (
    error: NetworkConnectivityError,
    retry: () => void,
    url: string
  ) => ReactNode;

  /**
   * Used when a response that unexpectedly receives a NotOK status code; >=400
   */
  renderNotOkFetchResponse: (
    error: NotOkFetchResponse,
    retry: () => void,
    url: string,
    status: number
  ) => ReactNode;

  /**
   * Used when an unknown fetch error occurs, perhaps due to app-specific authentication handling
   */
  renderUnknownRejectedFetchError: (
    error: Error,
    retry: () => void
  ) => ReactNode;
}

interface HttpClientErrorBoundaryState {
  rejectedFetchError: RejectedFetchError | null;
  notOkFetchResponse: NotOkFetchResponse | null;
}

/**
 * Use this error boundary, typically around your app.
 * Provide various render props for rendering different types of errors
 */
export class HttpClientErrorBoundary extends Component<
  HttpClientErrorBoundaryProps,
  HttpClientErrorBoundaryState
> {
  state: HttpClientErrorBoundaryState = {
    rejectedFetchError: null,
    notOkFetchResponse: null
  };

  componentDidCatch(error: any) {
    if (error instanceof RejectedFetchError) {
      error.clearError();
      this.setState({
        rejectedFetchError: error,
        notOkFetchResponse: null
      });
      /* eslint-disable-next-line no-console */
      console.error('Caught a RejectedFetchError', error, error.innerError);
      return;
    }

    if (error instanceof NotOkFetchResponse) {
      /* eslint-disable-next-line no-console */
      console.error(
        'Caught a NotOkFetchResponse',
        error,
        error.response,
        error.body
      );
      this.setState({
        rejectedFetchError: null,
        notOkFetchResponse: error
      });
      return;
    }

    // Not an error that we can handle
    throw error;
  }

  retry = () => {
    this.setState({
      rejectedFetchError: null,
      notOkFetchResponse: null
    });
  };

  render() {
    const {
      state: { rejectedFetchError, notOkFetchResponse },
      props: {
        renderNotOkFetchResponse,
        renderServiceUnavailable,
        renderNetworkConnectivity,
        renderUnknownRejectedFetchError,
        children
      },
      retry
    } = this;
    if (rejectedFetchError) {
      const { innerError } = rejectedFetchError;
      if (innerError instanceof ServiceUnavailableError) {
        return renderServiceUnavailable(
          innerError,
          retry,
          innerError?.url,
          innerError?.status
        );
      }
      if (innerError instanceof NetworkConnectivityError) {
        return renderNetworkConnectivity(innerError, retry, innerError?.url);
      }
      return renderUnknownRejectedFetchError(innerError, retry);
    }

    if (notOkFetchResponse) {
      return renderNotOkFetchResponse(
        notOkFetchResponse,
        retry,
        notOkFetchResponse?.url,
        notOkFetchResponse?.status
      );
    }

    return children;
  }
}
